var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-position", class: { disabled: _vm.isLocked } },
    _vm._l(_vm.positions, function (p) {
      return _c("div", {
        key: p,
        staticClass: "pos",
        class: { active: p === _vm.pos },
        attrs: { "data-pos": p },
        domProps: { innerHTML: _vm._s(p) },
        on: { click: _vm.onClick },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }