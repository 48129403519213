var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-classe" },
    [
      _c("h3", { class: _vm.getClass }, [
        _c("div", { staticClass: "grouping" }, [
          _c("div", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.classname) },
          }),
          _c("div", {
            staticClass: "hours",
            domProps: { innerHTML: _vm._s(_vm.getStartingHour) },
          }),
        ]),
        _vm.canEdit && !_vm.isEdited
          ? _c(
              "button",
              { on: { click: _vm.onChangeEdit } },
              [_c("font-awesome-icon", { attrs: { icon: "edit" } })],
              1
            )
          : _vm._e(),
      ]),
      _vm._l(_vm.dogs, function (dog) {
        return _c(
          "div",
          {
            key: dog.nr_cat,
            staticClass: "chien",
            attrs: {
              "data-dog-id": dog.nr_cat,
              set: (_vm.result = _vm.getResult(dog)),
            },
          },
          [
            _c(
              "div",
              { staticClass: "ref" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "chien", params: { id: dog.nr_cat } },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.getFormatedIdentifier(dog)) + " ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "nr-cat" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "chien", params: { id: dog.nr_cat } },
                    },
                  },
                  [_vm._v(" " + _vm._s(dog.nr_cat) + " ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "logos" },
              [
                dog.commentaire
                  ? _c("CommentLogo", { staticClass: "comment" }, [
                      _c("title", {
                        domProps: { innerHTML: _vm._s(dog.commentaire) },
                      }),
                    ])
                  : _vm._e(),
                dog.recompenses
                  ? _c("font-awesome-icon", {
                      attrs: {
                        icon: "certificate",
                        title: _vm.getTranslatedRewards(dog.recompenses),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            !_vm.isEdited
              ? _c(
                  "div",
                  { staticClass: "position" },
                  [
                    _c("select-position", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.result.canChangePosition,
                          expression: "result.canChangePosition",
                        },
                      ],
                      attrs: {
                        pos: parseInt(_vm.result.classement),
                        max_pos: _vm.result.posMax,
                        "is-locked": _vm.isLocked,
                      },
                      on: {
                        "position-changed": function ($event) {
                          return _vm.onPositionChanged($event, dog)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isEdited
              ? _c("div", { staticClass: "resultats" }, [
                  _vm._v(" " + _vm._s(_vm.result.qualifier) + " "),
                ])
              : _vm._e(),
            _vm.isEdited
              ? _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _c("select-qualification", {
                      attrs: {
                        qualifiers: _vm.getClassQualifiers,
                        value: _vm.result.qualifier_id || "",
                      },
                      on: {
                        "value-changed": function ($event) {
                          return _vm.onEditValueChange($event, dog)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      }),
      _c("div", { staticClass: "actions" }, [
        _vm.isEdited
          ? _c(
              "button",
              { staticClass: "action cancel", on: { click: _vm.onCancel } },
              [_vm._v(" " + _vm._s(_vm.$t("global.reset")) + " ")]
            )
          : _vm._e(),
        _vm.isEdited
          ? _c(
              "button",
              { staticClass: "action submit", on: { click: _vm.onSubmit } },
              [_vm._v(" " + _vm._s(_vm.$t("global.submit")) + " ")]
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }