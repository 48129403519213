var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-race" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("fil-ariane", { attrs: { navigation: _vm.navigation } }),
        _c("h1", [
          _vm._v(
            " " +
              _vm._s(_vm.getRaceLabel(_vm.getRaceById(_vm.dogs[0].race_id))) +
              " "
          ),
        ]),
        _c("h2", [
          _vm._v(
            _vm._s(
              _vm.$tc("race.dog", this.dogs.length, { count: this.dogs.length })
            )
          ),
        ]),
        _vm.showRewardsLink()
          ? _c("RewardLogo", { staticClass: "reward" })
          : _vm._e(),
        _vm.showRewardsLink()
          ? _c("router-link", {
              staticClass: "link-to-rewards",
              attrs: {
                to: {
                  name: "reward",
                  params: { race_id: _vm.dogs[0].race_id },
                },
              },
              domProps: { innerHTML: _vm._s(_vm.$t("race.go_to_rewards")) },
            })
          : _vm._e(),
        _vm._l(_vm.dogsByClassAndResults, function (list) {
          return _c("detail-classe", {
            key: list.key,
            attrs: { dogs: list.dogs, classname: list.name },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }